import { Routes, Route, useLocation } from 'react-router-dom'
import Login from './containers/auth/login'
import Launders from './containers/pages/Launders';
import Services from './containers/pages/Services';
import Clients from './containers/pages/Clients';
import Reports from './containers/pages/Reports';
import Landing from './containers/pages/Landing';
import Payment from './containers/pages/Payment';
import TermsAndCondition from './containers/pages/termsAndConditions';
import Profile from './containers/pages/Profile';


function AnimatedRoutes () {  
    const location = useLocation()

    return (
        <Routes location={location} key={location.pathname}>
            {/* Errors pages */}
            {/* <Route path="*" element={<Error404 />}/> */}
            

            {/* Authentication */}
            <Route exact path="/login" element={<Login />} />
            {/* <Route exact path="/signup" element={<Signup />} />
            <Route exact path="/reset_password" element={<ResetPassword />} />
            <Route exact path="/password/reset/confirm/:uid/:token" element={<ResetPasswordConfirm />} /> */}
            
            {/* Landing Display */}
            <Route exact path="/" element={<Landing />} />
            <Route exact path="/payment" element={<Payment />} />
            <Route exact path="/terms&conditions" element={<TermsAndCondition />} />

            {/* APP Display */}
            <Route exact path="/app/launders" element={<Launders />} />
            <Route exact path="/app/services" element={<Services />} />
            <Route exact path="/app/clients" element={<Clients />} />
            <Route exact path="/app/reports" element={<Reports />} />
            <Route excat path="/app/profile" element={<Profile />} />
        </Routes>

    );
}

export default AnimatedRoutes;