import { Link } from "react-router-dom";
import Launders from "../../assets/img/launders-screenshoot.png";


function Hero () {
    return(
        <section className="h-[75vh] lg:h-screen flex justify-between">
            <div className="z-10 ml-16 mt-20 lg:mt-44">
                <h1 className="z-15 text-white text-5xl font-semibold max-w-xl">Mejorá la gestión de tu lavanderia con nuestra solución digital</h1>
                <p className="text-gray-400 text-xl max-w-xl pt-3">
                    Optimización y automatización en un solo lugar. Digitaliza tus procesos, notificá a tus clientes automaticamente y obtené informes detallados de tus ingresos.
                </p>
                <div className="flex gap-x-4 text-white mt-7">
                    <Link to="/payment" className="bg-indigo-600 border-2 border-indigo-600 py-2 px-3 rounded-lg hover:bg-indigo-800 hover:border-indigo-800 transition duration-150">
                        Probar gratis
                    </Link>
                </div>
            </div>
            <img
            className="h-2/3 mt-20 z-10"
            src={Launders}
            alt="" />
        </section>
    )
};


export default Hero;