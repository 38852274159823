// components/launders/Card.js
import React from 'react';
import 'boxicons';

const Card = ({ id, service_name, price, onSelect, isSelected }) => (
    <li
        onClick={() => onSelect(id)}
        className={`p-2 bg-gray-900 hover:bg-gray-800 mb-1 rounded-lg cursor-pointer ${isSelected ? 'border-l-4 border-indigo-600' : ''}`}
    >
        <h2 className="pl-1 text-white text-xl font-semibold">{service_name}</h2>
        <p className='pl-1'>Precio: ${price}</p>

    </li>
);

export default Card;
