import { Link } from "react-router-dom";

function PricingCard({ title, price, data, button, isImportant }) {
    return (
        <div className="relative border-[1px] overflow-hidden border-gray-800 rounded-xl p-7 pb-10 w-2/5 h-full">
            {isImportant? 
                <div className="z-0 bg-indigo-600 absolute rounded-tl-full bottom-0 right-0 h-28 w-full blur-[100px]"></div> 
                : null}
            
            <h3 className="relative z-10 text-2xl pb-6 text-indigo-600">
                {title}
            </h3>
            <div className="relative z-10 flex items-baseline">
                <p className={`text-5xl pb-6 ${isImportant ? 'text-indigo-600' : 'text-white'}`}>
                    ${price}
                </p>
                {isImportant? <span>/mes</span>:<span>/7 días</span>}
            </div>
            <ul className="relative z-10 list-disc pl-5 pb-8">
                {data.map((item, index) => (
                    <li className="text-lg text-gray-400 p-1" key={index}>{item}</li>
                ))}
            </ul>
            <Link to="/payment" className={`relative z-10 border-[1px] p-4 rounded-xl transition duration-200 ${isImportant ? 'bg-indigo-600 border-indigo-600 hover:bg-indigo-800 hover:border-indigo-800' : 'bg-gray-900 border-gray-900 hover:bg-gray-800 hover:border-gray-800'}`}>
                {button}
            </Link>
        </div>
    );
}

export default PricingCard;
