import 'boxicons';


function ServiceDetail({
    service_name,
    price,
    delete_service,
    onEdit
}){
    return(
        <div className="ml-8 mt-2 h-[calc(100vh-200px)] overflow-y-auto">
            <div className='flex justify-between'>
                <h1 className="text-4xl font-bold">Detalle del servicio:</h1>
                <div className='flex gap-4'>
                    <i onClick={onEdit} class='bx bxs-pencil text-white text-4xl'></i>
                    <i onClick={() => delete_service(service_name)} class='bx bxs-trash text-red-600 text-4xl mr-2'></i>
                </div>
            </div>
            <div className="ml-4">
                <p className="mt-2 text-xl"><span className="font-semibold">Nombre:</span> {service_name}</p>
                <p className="mt-2 text-xl"><span className="font-semibold">Precio:</span> {price}</p>
            </div>
        </div>
    )
}; 


export default ServiceDetail;