import Layout from "../../hocs/layouts/Layout";
import ChartComponent from "../../components/reports/Chart";
import Data from "../../components/reports/data";
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from "sonner";


function Reports() {
    const [data, setData] = useState({
        lastWeekData: [],
        lastWeekSummary: { clients: 0, launnders: 0, total_revenue: 0 },
        lastMonthData: [],
        lastMonthSummary: { clients: 0, launnders: 0, total_revenue: 0 },
        lastYearData: [],
        lastYearSummary: { clients: 0, launnders: 0, total_revenue: 0 }
    });

    useEffect(() => {
        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access')}`
            }
        };
    
        axios.get(`${process.env.REACT_APP_API_URL}/api/v1/launders/launder-data/`, config)
            .then(response => {
                // Ordenar los datos sin eliminar duplicados
                const sortedLastWeekData = [...(response.data.week.data || [])].sort((a, b) => new Date(a.time) - new Date(b.time));
                const sortedLastMonthData = [...(response.data.month.data || [])].sort((a, b) => new Date(a.time) - new Date(b.time));
                const sortedLastYearData = [...(response.data.year.data || [])].sort((a, b) => new Date(a.time) - new Date(b.time));
    
                setData({
                    lastWeekData: sortedLastWeekData,
                    lastWeekSummary: response.data.week.summary,
                    lastMonthData: sortedLastMonthData,
                    lastMonthSummary: response.data.month.summary,
                    lastYearData: sortedLastYearData,
                    lastYearSummary: response.data.year.summary
                });
            })
            .catch(error => {
                toast.error("Ocurrió un error inesperado al generar los informes.");
            });
    }, []);

    return (
        <Layout>
            <div className="flex bg-gray-950 mt-4 gap-4 text-white">
                <div className="w-1/3 bg-gray-900 p-4 rounded-xl">
                    <p className="text-3xl font-semibold pb-4">Ultima semana:</p>
                    <ChartComponent data={data.lastWeekData} />
                </div>
                <div className="w-1/3 bg-gray-900 p-4 rounded-xl">
                    <p className="text-3xl font-semibold pb-4">Ultimo mes:</p>
                    <ChartComponent data={data.lastMonthData} />
                </div>
                <div className="w-1/3 bg-gray-900 p-4 rounded-xl">
                    <p className="text-3xl font-semibold pb-4">Ultimo año:</p>
                    <ChartComponent data={data.lastYearData} />
                </div>
            </div>
            <div className="flex bg-gray-950 mt-4 mb-8 gap-4 text-white">
                <div className="w-1/3 bg-gray-900 p-4 rounded-xl">
                    <Data 
                        clients={data.lastWeekSummary.clients}
                        launders={data.lastWeekSummary.launders}
                        total={data.lastWeekSummary.total_revenue}
                    />
                </div>
                <div className="w-1/3 bg-gray-900 p-4 rounded-xl">
                    <Data 
                        clients={data.lastMonthSummary.clients}
                        launders={data.lastMonthSummary.launders}
                        total={data.lastMonthSummary.total_revenue}
                    />
                </div>
                <div className="w-1/3 bg-gray-900 p-4 rounded-xl">
                    <Data 
                        clients={data.lastYearSummary.clients}
                        launders={data.lastYearSummary.launders}
                        total={data.lastYearSummary.total_revenue}
                    />
                </div>
            </div>
        </Layout>
    );
}

export default Reports;
