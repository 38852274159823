import { Link, Navigate } from "react-router-dom";
import { connect } from 'react-redux';
import { login } from "../../redux/actions/auth";
import { useEffect, useState } from "react";
import { OneEightyRing } from "react-svg-spinners";
import Logo from "../../assets/img/isotipo-negativo-monocromo.svg";


function Login({ login, loading, isAuthenticated, user }) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const { email, password } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = e => {
        e.preventDefault();
        login(email, password);
    };

    if (isAuthenticated && user) {
        if (user.has_paid_subscription && !loading) {
            return <Navigate to='/app/launders' />;
        } else if (user.has_paid_subscription === false) {
            return <Navigate to='/payment' />;
        }
    }

    return (
        <div className="min-h-screen flex items-center justify-center w-full bg-gray-950">
            <div className="bg-gray-900 shadow-md rounded-lg px-8 py-6 max-w-md">
                <Link to="/" className="flex justify-center gap-1 items-center pb-3">
                    <img
                    src={Logo}
                    height={40}
                    width={40}
                    alt="Logo" />
                    <p className="text-white text-3xl font-semibold">LavaManager</p>
                </Link>
                <form onSubmit={onSubmit} method="POST" className="w-80">
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-300 mb-2">Correo eléctronico</label>
                        <input 
                            type="email" 
                            id="email" 
                            name="email" 
                            value={email} 
                            onChange={onChange} 
                            className="shadow-sm rounded-md w-full px-3 py-2 border border-gray-300 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" 
                            placeholder="ejemplo@email.com" 
                            required 
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="password" className="block text-sm font-medium text-gray-300 mb-2">Contraseña</label>
                        <input 
                            type="password" 
                            id="password" 
                            name="password" 
                            value={password} 
                            onChange={onChange} 
                            className="shadow-sm rounded-md w-full px-3 py-2 border border-gray-300 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" 
                            placeholder="Ingresa tu contraseña" 
                            required 
                        />
                        <div className="flex items-center justify-between mb-4">
                            <Link to="#"
                                className="text-xs text-gray-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                Forgot Password?
                            </Link>
                            <Link to="/payment"
                                className="text-xs text-indigo-500 hover:text-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                Create una cuenta
                            </Link>
                        </div>
                    </div>
                    
                    {loading ?  
                    <button className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <OneEightyRing 
                        width={25}
                        height={25}
                        color="#fff" 
                    />
                    </button>
                    :<button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Iniciar sesión</button>
                    }
                    
                </form>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    loading: state.Auth.loading,
    isAuthenticated: state.Auth.isAuthenticated,
    user: state.Auth.user,
});

export default connect(mapStateToProps, { login })(Login);