import {
    GET_CLIENTS_SUCCESS,
    GET_CLIENTS_FAIL,
    DELETE_CLIENTS_SUCCESS,
    DELETE_CLIENTS_FAIL,
    CREATE_CLIENTS_SUCCESS,
    CREATE_CLIENTS_FAIL,
    EDIT_CLIENTS_SUCCESS,
    EDIT_CLIENTS_FAIL,
    SETTLE_ACCOUNT_SUCCESS,
    SETTLE_ACCOUNT_FAIL
} from './types';
import axios from 'axios';
import { toast } from 'sonner';


export const get_clients = () => async dispatch => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/clients/list-clients/`, config);

        if (res.status === 200) {
            dispatch({
                type: GET_CLIENTS_SUCCESS,
                payload: res.data
            });
        } else {
            dispatch({
                type: GET_CLIENTS_FAIL
            });
        }
    }
    catch(err) {
        dispatch({
            type: GET_CLIENTS_FAIL
        })
    }
};


export const delete_client = (dni) => async dispatch => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
        }
    };

    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/clients/delete-client/${dni}/`, config);

        if(res.status === 200) {
            dispatch({
                type: DELETE_CLIENTS_SUCCESS,
                payload: dni
            });
            toast.success("Se borró exitosamente el cliente.");
        } else {
            dispatch({
                type: DELETE_CLIENTS_FAIL
            });
            toast.error("Ocurrió un error inesperado al borrar el cliente.");
        };
    } catch(err){
        dispatch({
            type: DELETE_CLIENTS_FAIL
        });
        toast.error("Ocurrió un error inesperado al borrar el cliente.");
    };
};


export const create_client = (clientData) => async dispatch => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
        }
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/clients/create-client/`, clientData, config);

        if(res.status === 201) {
            dispatch({
                type: CREATE_CLIENTS_SUCCESS,
                payload: res.data.client
            });
            toast.success(res.data.success)
        } else {
            dispatch({
                type: CREATE_CLIENTS_FAIL
            });
            toast.error("Ocurrió un error inesperado al crear un nuevo cliente.");
        }
    } catch(err) {
        dispatch({
            type: CREATE_CLIENTS_FAIL
        });
        toast.error("Ocurrió un error inesperado al crear un nuevo cliente.");
    };

};


export const edit_client = (clientData) => async dispatch => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
        }
    };

    try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/clients/update-client/`, clientData, config);

        if(res.status === 200) {
            dispatch({
                type: EDIT_CLIENTS_SUCCESS,
                payload: res.data.client
            });
            toast.success(res.data.success)
        } else {
            dispatch({
                type: EDIT_CLIENTS_FAIL
            });
            toast.error("Ocurrió un error inesperado al editar al cliente.");
        }
    } catch(err){
        dispatch({
            type: EDIT_CLIENTS_FAIL
        });
        toast.error("Ocurrió un error inesperado al editar al cliente.");
    };
};


export const settle_account = (client_id) => async dispatch => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
        }
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/clients/settle-account/${client_id}/`, null,config);

        if(res.status === 200) {
            dispatch({
                type: SETTLE_ACCOUNT_SUCCESS,
                payload: res.data.client
            });
            toast.success(res.data.message);
        } else {
            dispatch({
                type: SETTLE_ACCOUNT_FAIL
            });
            toast.error("Ocurrió un error inesperado al saldar la cuenta corriente.");
        };

    } catch(err){
        dispatch({
            type: SETTLE_ACCOUNT_FAIL
        });
        toast.error("Ocurrió un error inesperado al saldar la cuenta corriente.");
    }
}