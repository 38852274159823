import { createChart, ColorType } from 'lightweight-charts';
import React, { useEffect, useRef } from 'react';


export const ChartComponent = props => {
    const {
        data,
        colors: {
            backgroundColor = 'rgba(17, 24, 38, 0)',
            lineColor = 'rgb(79,70,229)',
            textColor = 'white',
            areaTopColor = 'rgb(79,70,229)',
            areaBottomColor = 'rgba(17, 24, 38, 0)',
            
        } = {},
    } = props;

    

    const chartContainerRef = useRef();

    useEffect(
        () => {
            const handleResize = () => {
                chart.applyOptions({ width: chartContainerRef.current.clientWidth });
            };

            const chart = createChart(chartContainerRef.current, {
                layout: {
                    background: { type: ColorType.Solid, color: backgroundColor },
                    textColor,
                },
                width: chartContainerRef.current.clientWidth,
                height: 300,
            });
            chart.timeScale().fitContent();

            const currentLocale = window.navigator.languages[0];
            // Create a number format using Intl.NumberFormat
            const myPriceFormatter = Intl.NumberFormat(currentLocale, {
                style: 'currency',
                currency: 'ARS', // Currency for data points
            }).format;

            // Apply the custom priceFormatter to the chart
            chart.applyOptions({
                localization: {
                    priceFormatter: myPriceFormatter,
                },
            });

            const newSeries = chart.addAreaSeries({ lineColor, topColor: areaTopColor, bottomColor: areaBottomColor, });
            newSeries.setData(data);

            window.addEventListener('resize', handleResize);

            return () => {
                window.removeEventListener('resize', handleResize);

                chart.remove();
            };
        },
        [data, backgroundColor, lineColor, textColor, areaTopColor, areaBottomColor]
    );

    return (
        <div
            ref={chartContainerRef}
        />
    );
};


export default ChartComponent;