import {
    GET_CLIENTS_SUCCESS,
    GET_CLIENTS_FAIL,
    DELETE_CLIENTS_SUCCESS,
    DELETE_CLIENTS_FAIL,
    CREATE_CLIENTS_SUCCESS,
    CREATE_CLIENTS_FAIL,
    EDIT_CLIENTS_SUCCESS,
    EDIT_CLIENTS_FAIL,
    SETTLE_ACCOUNT_SUCCESS,
    SETTLE_ACCOUNT_FAIL
} from '../actions/types';

const initialState = {
    clients: null
};

export default function Clients(state= initialState, action) { 
    const { type, payload } = action

    switch(type) {
        case GET_CLIENTS_SUCCESS:
            return {
                ...state,
                clients: payload
            }

        case GET_CLIENTS_FAIL:
            return{
                ...state,
                clients: null
            }

        case DELETE_CLIENTS_SUCCESS:
            return {
                ...state,
                clients: state.clients.filter(client => client.dni !== payload)
            }

        case DELETE_CLIENTS_FAIL:
            return state;
            
        case CREATE_CLIENTS_SUCCESS:
            return {
                ...state,
                clients: [...state.clients, payload]
            }

        case CREATE_CLIENTS_FAIL:
            return state;

        case EDIT_CLIENTS_SUCCESS:
            return {
                ...state,
                clients: state.clients.map(client => client.id === payload.id ? payload : client)
            }

        case EDIT_CLIENTS_FAIL:
            return state;

        case SETTLE_ACCOUNT_SUCCESS:
            return {
                ...state,
                clients: state.clients.map(client => client.id === payload.id ? payload : client)
            }
        case SETTLE_ACCOUNT_FAIL:
            return state;
        default:
            return state;
    }
}