import 'boxicons';
import { useState } from 'react';
import { OneEightyRing } from "react-svg-spinners";

function LaunderDetail ({
        id,
        status,
        client_name,
        client_email,
        client_phone,
        client_dni,
        total_price,
        payment_total, 
        offered_services, 
        pickup_time, 
        input_time,
        num_baskets,
        order_number,
        mark_as_paid, 
        mark_as_finish, 
        delete_launder 
    }) {
    
    const [loading, setLoading] = useState(false);

    const handleMarkAsFinish = async (id) => {
        setLoading(true);
        await mark_as_finish(id);
        setLoading(false);
    };

    return (
        <div key={id} className="ml-8 mt-2 h-[calc(100vh-200px)]">
            <div className="flex justify-between items-center">
                <h1 className="text-4xl font-bold">Detalle del lavado: {status}</h1>
                <i onClick={() => delete_launder()} className='bx bxs-trash text-red-600 text-4xl mr-2'></i>
            </div>
            {order_number && order_number != null? <p className='text-2xl pt-2'>Numero de order: {order_number}</p>: <span></span>}
            <h3 className="text-2xl font-bold ml-6 my-2 lg:mt-4">Cliente:</h3>
            <div className="px-6 py-4 ml-6 bg-gray-800 rounded-lg inline-block">
                <p className="text-xl"><span className="font-semibold">Nombre:</span> {client_name}</p>
                <p className="mt-2 text-xl"><span className="font-semibold">DNI:</span> {client_dni}</p>
                <p className="mt-2 text-xl"><span className="font-semibold">Email:</span> {client_email}</p>
                <p className="mt-2 text-xl"><span className="font-semibold">Teléfono:</span> {client_phone}</p>
            </div>  
            <h3 className="text-2xl font-semibold ml-6 mt-1 lg:mt-4">Servicios ofrecidos: ${total_price}</h3>
            <ul>
                {offered_services && offered_services.map(service => (
                    <li key={service.id_service} className="text-xl pl-4 ml-6">
                        {service.service_name} - ${service.service_price}
                    </li>
                ))}
            </ul>
            <div className="flex gap-4">
                <p className="text-lg ml-6 mt-2 lg:mt-4"><span className="font-semibold">Ingreso a las: </span>{input_time}</p>
                <p className="text-lg ml-6 mt-2 lg:mt-4"><span className="font-semibold">Debe estar listo para: </span>{pickup_time}</p>
            </div>
            <p className="text-lg ml-6 mt-2 lg:mt-4"><span className="font-semibold">Cantidad de canastos:</span> {num_baskets}</p>
            <div className="ml-6 mt-1 lg:mt-4">
                <p className="text-xl"><span className="font-semibold">Total pagado:</span> {payment_total === total_price ? <span className="text-green-600">${payment_total}</span> : <span className="text-red-600">${payment_total}</span>}</p>
            </div>
            <div className="flex gap-4 ml-6 mt-2 pb-6 lg:mt-6">
                <button onClick={() => mark_as_paid(id)} className="w-36 flex justify-center py-2 px-2 border border-transparent rounded-md shadow-sm text-md font-medium text-white bg-green-600 hover:bg-green-700">
                    Abonár
                </button>
                {!loading ?
                    <button onClick={() => handleMarkAsFinish(id)} className="w-36 flex justify-center py-2 px-2 border border-transparent rounded-md shadow-sm text-md font-medium text-white bg-indigo-600 hover:bg-indigo-700">
                        Terminar
                    </button>
                    :
                    <button className="w-36 flex justify-center py-2 px-2 border border-transparent rounded-md shadow-sm text-md font-medium text-white bg-indigo-600 hover:bg-indigo-700">
                        <OneEightyRing 
                            width={23}
                            height={23}
                            color="#d0cfcd" />
                    </button>
                }
            </div>
        </div>
    )
};

export default LaunderDetail;
