// Authentication
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';
export const LOGIN_SUCCES = 'LOGIN_SUCCES';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const USER_LOADED_SUCCESS = 'USER_LOADED_SUCCESS';
export const USER_LOADED_FAIL = 'USER_LOADED_FAIL';
export const SET_AUTH_LOADING = 'SET_AUTH_LOADING';
export const REMOVE_AUTH_LOADING = 'REMOVE_AUTH_LOADING';
export const AUTHENTICATED_SUCCESS = 'AUTHENTICATED_SUCCESS';
export const AUTHENTICATED_FAIL = 'AUTHENTICATED_FAIL';
export const REFRESH_SUCCESS = 'REFRESH_SUCCESS';
export const REFRESH_FAIL = 'REFRESH_FAIL';
export const LOGOUT = 'LOGOUT';

export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
export const RESET_PASSWORD_CONFIRM_SUCCESS = 'RESET_PASSWORD_CONFIRM_SUCCESS';
export const RESET_PASSWORD_CONFIRM_FAIL = 'RESET_PASSWORD_CONFIRM_FAIL';

// Alerts
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

// Launders
export const GET_LAUNDERS_SUCCESS = 'GET_LAUNDERS_SUCCESS';
export const GET_LAUNDERS_FAIL = 'GET_LAUNDERS_FAIL';
export const MARK_AS_PAID_SUCCESS = 'MARK_AS_PAID_SUCCESS';
export const MARK_AS_PAID_FAIL = 'MARK_AS_PAID_FAIL';
export const MARK_AS_FINISH_SUCCESS = 'MARK_AS_FINISH_SUCCESS';
export const MARK_AS_FINISH_FAIL = 'MARK_AS_FINISH_FAIL';
export const DELETE_LAUNDER_SUCCESS = 'DELETE_LAUNDER_SUCCESS';
export const DELETE_LAUNDER_FAIL = 'DELETE_LAUNDER_FAIL';
export const CREATE_LAUNDER_SUCCESS = 'CREATE_LAUNDER_SUCCESS';
export const CREATE_LAUNDER_FAIL = 'CREATE_LAUNDER_FAIL';

// Clients
export const GET_CLIENTS_SUCCESS = 'GET_CLIENTS_SUCCESS';
export const GET_CLIENTS_FAIL = 'GET_CLIENTS_FAIL';
export const DELETE_CLIENTS_SUCCESS = 'DELETE_CLIENTS_SUCCESS';
export const DELETE_CLIENTS_FAIL = 'DELETE_CLIENTS_FAIL';
export const CREATE_CLIENTS_SUCCESS = 'CREATE_CLIENTS_SUCCESS';
export const CREATE_CLIENTS_FAIL = 'CREATE_CLIENTS_FAIL';
export const EDIT_CLIENTS_SUCCESS = 'EDIT_CLIENTS_SUCCESS';
export const EDIT_CLIENTS_FAIL = 'EDIT_CLIENTS_FAIL';
export const SETTLE_ACCOUNT_SUCCESS = 'SETTLE_ACCOUNT_SUCCESS';
export const SETTLE_ACCOUNT_FAIL = 'SETTLE_ACCOUNT_FAIL';

// Service
export const GET_SERVICES_SUCCESS = 'GET_SERVICES_SUCCESS';
export const GET_SERVICES_FAIL = 'GET_SERVICES_FAIL';
export const DELETE_SERVICE_SUCCESS = 'DELETE_SERVICE_SUCCESS';
export const DELETE_SERVICE_FAIL = 'DELETE_SERVICE_FAIL';
export const CREATE_SERVICE_SUCCESS = 'CREATE_SERVICE_SUCCESS';
export const CREATE_SERVICE_FAIL = 'CREATE_SERVICE_FAIL';
export const EDIT_SERVICE_SUCCESS = 'EDIT_SERVICE_SUCCESS';
export const EDIT_SERVICE_FAIL = 'EDIT_SERVICE_FAIL';