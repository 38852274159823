import React from 'react';
import 'boxicons';

const Card = ({ id, status, client_name, offered_services, payment_total, total_price, date, order_number, onSelect, isSelected }) => (
    <li
        onClick={() => onSelect(id)}
        className={`p-2 bg-gray-900 hover:bg-gray-800 mb-1 rounded-lg cursor-pointer ${isSelected ? 'border-l-4 border-indigo-600' : ''}`}
    >
        <div className='flex justify-between'>
            <h2 className="text-white text-xl font-semibold">{client_name}</h2>
            {order_number && order_number != null? <p>{order_number}</p>: <span></span>}
        </div>
        <span className="flex gap-1">
            <i className='bx bxs-t-shirt text-white text-xl'></i>
            {offered_services && offered_services.map((service, index) => (
                <p key={index}>- {service.service_name}</p>
            ))}
        </span>
        <p>Abonó {payment_total !== total_price ? <span className="text-red-600">${payment_total}</span> : <span className="text-green-500">${payment_total}</span>} de <span className="text-green-500">${total_price}</span></p>
        <div className="flex justify-between items-center">
            <p className="text-base">{date}</p>
            <div className="flex gap-2">
                <span>{payment_total !== total_price ? <i className='bx bx-money text-gray-700 text-4xl'></i> : <i className='bx bx-money text-white text-4xl'></i>}</span>
                <span>{status !== 'Terminado' ? <i className='bx bx-envelope text-gray-700 text-4xl'></i> : <i className='bx bx-envelope text-white text-4xl'></i>}</span>
            </div>
        </div>
    </li>
);

export default Card;