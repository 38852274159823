import NavBar from "../../components/landing/Navbar"

function TermsAndCondition () {
    return(
        <main className="bg-gray-950 min-h-screen">
            <NavBar />
            <div className="max-w-4xl mx-auto text-white p-8 rounded shadow-lg">
                <h1 className="text-3xl font-bold mb-6 text-center">Términos y Condiciones de Lavamanager</h1>

                <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">1. Información General</h2>
                <p className="mb-2">Estos Términos y Condiciones regulan el acceso y uso de la aplicación <strong>Lavamanager</strong>, propiedad de <strong>Vainfax</strong>. Al utilizar este servicio, el cliente acepta estos Términos y Condiciones en su totalidad.</p>
                <ul className="list-disc list-inside mb-4">
                    <li><strong>Nombre legal de la empresa:</strong> Vainfax</li>
                    <li><strong>Correo electrónico de contacto:</strong> contacto@vainfax.com</li>
                    <li><strong>Sitio web:</strong> <a href="https://vainfax.com" className="text-blue-500 hover:underline">vainfax.com</a></li>
                    <li><strong>Jurisdicción:</strong> Solo disponible en Argentina</li>
                </ul>
                </section>

                <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">2. Descripción del Servicio</h2>
                <p className="mb-2">Lavamanager es una aplicación SaaS destinada a la digitalización de datos en lavanderías, que permite a los usuarios registrar lavados, servicios, clientes, y generar informes de ingresos semanales, mensuales y anuales.</p>
                <p className="mb-2">El servicio se ofrece bajo un único plan de suscripción mensual con acceso completo a todas las funcionalidades.</p>
                </section>

                <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">3. Registro y Cuentas de Usuario</h2>
                <p className="mb-2">Para utilizar Lavamanager, los clientes deben registrarse completando un formulario y proporcionando los datos de pago a través del sistema de Mercado Pago. Cada cuenta es individual, y en caso de requerir múltiples usuarios, deberán crearse cuentas adicionales.</p>
                <p className="mb-2">El acceso al sistema está protegido mediante <strong>JWT</strong> en formato Bearer, y las contraseñas se cifran y almacenan de manera segura.</p>
                </section>

                <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">4. Planes, Precios y Pagos</h2>
                <ul className="list-disc list-inside mb-4">
                    <li><strong>Precio del plan de suscripción:</strong> ARS 30,298 mensuales.</li>
                    <li><strong>Período de prueba:</strong> 7 días con acceso completo al sistema.</li>
                    <li><strong>Métodos de pago:</strong> Tarjetas de crédito y débito a través de Mercado Pago.</li>
                    <li><strong>Frecuencia de facturación:</strong> Mensual.</li>
                </ul>
                <p className="mb-2">En caso de no realizar el pago en la fecha estipulada, se suspenderá el acceso al servicio hasta que se complete el pago.</p>
                </section>

                <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">5. Uso Aceptable y Responsabilidad del Cliente</h2>
                <p className="mb-2">El cliente es responsable de la exactitud y legalidad de los datos ingresados en el sistema. Lavamanager no se hace responsable por errores en los datos ingresados por el cliente.</p>
                <p className="mb-2">El uso indebido del servicio, incluidos actos ilegales o fraudulentos, está prohibido. Los menores de edad pueden utilizar el servicio bajo la responsabilidad de sus tutores legales.</p>
                </section>

                <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">6. Propiedad Intelectual</h2>
                <p className="mb-2"><strong>Carlos Alberto Richardson</strong>, como desarrollador, mantiene los derechos de propiedad intelectual de todo el software. Los clientes reciben una licencia limitada para usar el sistema, pero no tienen derecho a modificar, distribuir o revender el software.</p>
                </section>

                <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">7. Privacidad y Protección de Datos</h2>
                <p className="mb-2">Lavamanager recopila datos personales y comerciales con el único fin de ofrecer el servicio. La información se almacena de forma segura y no se comparte con terceros, salvo para procesar pagos a través de Mercado Pago.</p>
                <p className="mb-2">Cumplimos con las leyes de protección de datos de Argentina, y los usuarios pueden solicitar información sobre cómo se manejan sus datos o solicitar su eliminación en cualquier momento.</p>
                </section>

                <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">8. Disponibilidad y Mantenimiento del Servicio</h2>
                <p className="mb-2">Lavamanager no garantiza un tiempo de actividad específico, pero se compromete a mantener el servicio en funcionamiento dentro de lo razonable. En caso de mantenimiento programado o interrupciones, se notificará a los clientes por correo electrónico con antelación.</p>
                </section>

                <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">9. Soporte al Cliente</h2>
                <p className="mb-2">El soporte se ofrece exclusivamente por correo electrónico a través de contacto@vainfax.com. Intentamos responder a las consultas en el menor tiempo posible.</p>
                </section>

                <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">10. Limitación de Responsabilidad</h2>
                <p className="mb-2">Lavamanager se proporciona "tal cual", sin garantías explícitas. <strong>Vainfax</strong> no se hace responsable de daños indirectos, pérdida de datos, lucro cesante u otros daños derivados del uso del servicio.</p>
                </section>

                <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">11. Modificación de los Términos</h2>
                <p className="mb-2">Vainfax se reserva el derecho de modificar estos Términos y Condiciones en cualquier momento. Los cambios se notificarán a los clientes por correo electrónico y/o en el sitio web. Si un cliente no está de acuerdo con los nuevos términos, puede cancelar su suscripción sin penalización.</p>
                </section>

                <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">12. Cancelación y Terminación</h2>
                <p className="mb-2">El cliente puede cancelar su suscripción en cualquier momento. En caso de cancelación, los datos ingresados en el sistema se mantendrán almacenados y podrán ser recuperados si el cliente decide volver a suscribirse.</p>
                <p className="mb-2"><strong>Vainfax</strong> se reserva el derecho de suspender el acceso al servicio en caso de impago.</p>
                </section>

                <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">13. Ley Aplicable y Resolución de Disputas</h2>
                <p className="mb-2">Este acuerdo se rige por las leyes de Argentina, y cualquier disputa que surja en relación con estos Términos será resuelta en los tribunales de Argentina.</p>
                </section>
            </div>
        </main>
    );
}

export default TermsAndCondition;