import 'boxicons';


function Data({ clients, launders, total }){
    return(
        <article className="text-white block gap-4">
            <div className="w-1/3 justify-start flex gap-1 items-center text-xl">
                <i class='bx bxs-face text-white text-3xl'></i>Clientes: <span className='font-semibold'>{clients}</span>
            </div>
            <div className="w-1/3 justify-start flex gap-1 items-center text-xl">
                <i class='bx bx-basket text-white text-3xl' ></i>Lavados: <span className='font-semibold'>{launders}</span>
            </div>
            <div className="w-1/3 justify-start flex gap-1 items-center text-xl">
                <i class='bx bx-money text-white text-3xl'></i>Total: <span className='font-semibold'>${total}</span>
            </div>
        </article>
    )
}


export default Data;