import Hero from "../../components/landing/Hero";
import Navbar from "../../components/landing/Navbar";
import Features from "../../components/landing/Features";
import Pricing from "../../components/landing/Pricing";
import Footer from "../../components/landing/Footer";


function Landing(){
    return(
        <main className="bg-gray-950">
            <div className="z-0 bg-indigo-600 absolute rounded-br-full top-0 left-0 h-48 w-2/5 blur-[100px]">
            </div>
            <Navbar />
            <Hero />
            <Features />
            <Pricing />
            <Footer />
        </main>
    )
}

export default Landing;