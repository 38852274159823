import {
    GET_LAUNDERS_SUCCESS,
    GET_LAUNDERS_FAIL,
    MARK_AS_PAID_SUCCESS,
    MARK_AS_PAID_FAIL,
    MARK_AS_FINISH_SUCCESS,
    MARK_AS_FINISH_FAIL,
    DELETE_LAUNDER_SUCCESS,
    DELETE_LAUNDER_FAIL,
    CREATE_LAUNDER_SUCCESS,
    CREATE_LAUNDER_FAIL
} from './types';
import axios from 'axios';
import { toast } from 'sonner';


export const get_launders = () => async dispatch => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/launders/list-launders/`, config);

        if (res.status === 200) {
            dispatch({
                type: GET_LAUNDERS_SUCCESS,
                payload: res.data
            });
        } else {
            dispatch({
                type: GET_LAUNDERS_FAIL
            });
            toast.error("Ocurrió un error inesperado al cargar tus lavados.");
        }
    }
    catch(err) {
        dispatch({
            type: GET_LAUNDERS_FAIL
        });
        toast.error("Ocurrió un error inesperado al cargar tus lavados.");
    }
};


export const mark_as_paid = (id) => async dispatch => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
        }
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/launders/mark_as_paid/${id}/`, {}, config);

        if(res.status === 200) {
            dispatch({
                type: MARK_AS_PAID_SUCCESS,
                payload: res.data.launder
            });
            toast.success("Lavado pagado con éxito!");
        } else {
            dispatch({
                type: MARK_AS_PAID_FAIL
            });
            toast.error("Ocurrió un error inesperado al abonar el lavado.");
        };
    } catch(err) {
        dispatch({
            type: MARK_AS_PAID_FAIL
        });
        toast.error("Ocurrió un error inesperado al abonar el lavado.");
    };
};


export const mark_as_finish = (id) => async dispatch => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
        }
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/launders/mark_as_finish/${id}/`, {}, config);

        if(res.status === 200) {
            dispatch({
                type: MARK_AS_FINISH_SUCCESS,
                payload: res.data.launder
            });
            toast.success("Se abrío la ventana para notificar al cliente!");
            window.open(res.data.url_message, '_blank');
        } else {
            dispatch({
                type: MARK_AS_FINISH_FAIL
            });
            toast.error("Ocurrió un error inesperado al notificar al cliente.");
        };
    } catch(err) {
        dispatch({
            type: MARK_AS_FINISH_FAIL
        });
        toast.error("Ocurrió un error inesperado al notificar al cliente.");
    };
};


export const delete_launder = (id) => async dispatch => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
        }
    };

    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/launders/delete-launder/${id}/`, config);

        if(res.status === 204) {
            dispatch({
                type: DELETE_LAUNDER_SUCCESS,
                payload: id
            });
            toast.success("Se borró correctamente el lavado!");
        } else {
            dispatch({
                type: DELETE_LAUNDER_FAIL
            });
            toast.error("Ocurrió un error inesperado al borrar el lavado.");
        };
    } catch(err) {
        dispatch({
            type: DELETE_LAUNDER_FAIL
        });
        toast.error("Ocurrió un error inesperado al borrar el lavado.");
    };
};


export const create_launder = (launderData) => async dispatch => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
        }
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/launders/create-launder/`, launderData, config);

        if(res.status === 201) {
            dispatch({
                type: CREATE_LAUNDER_SUCCESS,
                payload: res.data.launder
            });
            console.log(CREATE_LAUNDER_SUCCESS)
            toast.success(res.data.success);
        } else {
            dispatch({
                type: CREATE_LAUNDER_FAIL
            });
            toast.error("Ocurrió un error inesperado al craer el lavado.");
        }
    } catch(err) {
        dispatch({
            type: CREATE_LAUNDER_FAIL
        });
        toast.error("Ocurrió un error inesperado al craer el lavado.");
    };

};