import { Provider } from 'react-redux';
import store from './store';
import AnimatedRoutes from './Routes';
import { BrowserRouter } from 'react-router-dom';
import { Toaster } from 'sonner';


function App() {
  return (
    <Provider store={store}>
      <BrowserRouter BrowserRouter>
        <AnimatedRoutes />
      </BrowserRouter>
      <Toaster
        position="bottom-right"
        expand={false}
        richColors
        closeButton
            />
    </Provider>
  );
}

export default App;
