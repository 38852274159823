import { connect } from 'react-redux';
import { CardPayment } from '@mercadopago/sdk-react';
import { initMercadoPago } from '@mercadopago/sdk-react';
import MercadoPagoSVG from "../../assets/img/MercadoPago.svg";
import { useNavigate } from 'react-router-dom';

initMercadoPago(`${process.env.REACT_APP_PUBLIC_KEY}`);

const PaymentForm = ({ user }) => {
    const navigate = useNavigate();

    const initialization = {
      amount: 30298, // Monto de la transacción
      payer: {
        email: user?.email
      },
      preferenceId: "2c938084922e71cf01924a63e77108be"
      
    };
  
    // Callback cuando se envía el formulario
    const onSubmit = async (formData) => {
      return new Promise((resolve, reject) => {
        const dataToSend = {
          ...formData,
          user_id: user.id  // Agrega el ID del usuario aquí
        };

        fetch(`${process.env.REACT_APP_API_URL}/api/v1/payment/create-subscription/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access')}`
          },
          body: JSON.stringify(dataToSend),
        })
          .then(response => response.json())
          .then(data => {
            // Manejar el resultado del pago
            resolve(data);
            navigate('/app/launders');
          })
          .catch(error => {
            // Manejar errores
            console.error(error);
            reject(error);
          });
      });
    };
  
    // Callback cuando hay un error en el Brick
    const onError = async (error) => {
      console.error('Error en el Brick:', error);
    };
  
    // Callback cuando el Brick está listo
    const onReady = async () => {
      console.log('Brick está listo');
    };
  
    return (
      <div className='px-8 text-gray-300'>
        <CardPayment
          initialization={initialization}
          onSubmit={onSubmit}
          onReady={onReady}
          onError={onError}
        />
        <div className='flex'>
          <p className='mt-4 text-lg'>Todos los pagos son procesados por Mercado Pago</p>
          <img src={MercadoPagoSVG} width={200} height={150} alt='' />
        </div>
      </div>
    );
  };


  const mapStateToProps = state => ({
    user: state.Auth.user
  });

export default connect(mapStateToProps) (PaymentForm);
