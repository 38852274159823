import { connect } from 'react-redux';
import { signup } from "../../redux/actions/auth";
import { useState } from "react";
import { OneEightyRing } from "react-svg-spinners";
import { Link } from 'react-router-dom';


function SignUp ({ signup, loading, isAuthenticated, handleButton }) {
    const [formData, setFormData] = useState({
        user_name: '',
        email: '',
        password: '',
        re_password: '',
        province: '',
        city: '',
        direction: '',
    });

    const { user_name, email, password, re_password, province, city, direction } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = e => {
        e.preventDefault();
        signup(user_name, email, password, re_password, province, city, direction);
    };

    if(isAuthenticated)
        handleButton();

    /* if (isAuthenticated && !loading) {
        return <Navigate to='/app/launders' />;
    } */

    return(
        <div className="w-full px-8 pb-8 pt-2">
            <div className="bg-gray-900 shadow-md rounded-lg px-8 py-6">
                <h1 className="text-3xl font-bold text-center mb-4 text-gray-200">Creá una cuenta gratis</h1>
                <form onSubmit={onSubmit} method="POST">
                    <div className="mb-2">
                        <label htmlFor="username" className="block text-sm font-medium text-gray-300 mb-1">Nombre de tu lavanderia</label>
                        <input 
                            type="text" 
                            id="user_name" 
                            name="user_name" 
                            value={user_name} 
                            onChange={onChange} 
                            className="shadow-sm rounded-md w-full px-3 py-2 border border-gray-300 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" 
                            placeholder="Lavanderia" 
                            required 
                        />
                    </div>
                    <div className="mb-2">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-300 mb-1">Correo eléctronico</label>
                        <input 
                            type="email" 
                            id="email" 
                            name="email" 
                            value={email} 
                            onChange={onChange} 
                            className="shadow-sm rounded-md w-full px-3 py-2 border border-gray-300 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" 
                            placeholder="ejemplo@email.com" 
                            required 
                        />
                    </div>
                    <div className="mb-2">
                        <label htmlFor="password" className="block text-sm font-medium text-gray-300 mb-1">Contraseña</label>
                        <input 
                            type="password" 
                            id="password" 
                            name="password" 
                            value={password} 
                            onChange={onChange} 
                            className="shadow-sm rounded-md w-full px-3 py-2 border border-gray-300 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" 
                            placeholder="Ingresa tu contraseña" 
                            required 
                        />
                    </div>
                    <div className="mb-2">
                        <label htmlFor="re_password" className="block text-sm font-medium text-gray-300 mb-1">Confirmar contraseña</label>
                        <input 
                            type="password" 
                            id="password" 
                            name="re_password" 
                            value={re_password} 
                            onChange={onChange} 
                            className="shadow-sm rounded-md w-full px-3 py-2 border border-gray-300 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" 
                            placeholder="Ingresa tu contraseña" 
                            required 
                        />
                    </div>
                    <div className="flex gap-4">
                        <div className="mb-2 w-full">
                            <label htmlFor="province" className="block text-sm font-medium text-gray-300 mb-1">Provincia</label>
                            <input 
                                type="text" 
                                id="province" 
                                name="province" 
                                value={province} 
                                onChange={onChange} 
                                className="shadow-sm rounded-md w-full px-3 py-2 border border-gray-300 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" 
                                placeholder="Tu provincia" 
                                required 
                            />
                        </div>
                        <div className="mb-2 w-full">
                            <label htmlFor="city" className="block text-sm font-medium text-gray-300 mb-1">Ciudad</label>
                            <input 
                                type="text" 
                                id="city" 
                                name="city" 
                                value={city} 
                                onChange={onChange} 
                                className="shadow-sm rounded-md w-full px-3 py-2 border border-gray-300 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" 
                                placeholder="Tu ciudad" 
                                required 
                            />
                        </div>
                    </div>
                    <div className="mb-6">
                        <label htmlFor="direction" className="block text-sm font-medium text-gray-300 mb-1">Dirección</label>
                        <input 
                            type="text" 
                            id="direction" 
                            name="direction" 
                            value={direction} 
                            onChange={onChange} 
                            className="shadow-sm rounded-md w-full px-3 py-2 border border-gray-300 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" 
                            placeholder="Tu dirección" 
                            required 
                        />
                    </div>
                    
                    {loading ?  
                    <button className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <OneEightyRing 
                        width={25}
                        height={25}
                        color="#fff" 
                    />
                    </button>
                    :<button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Iniciar sesión</button>
                    }
                    <p className='text-white pt-4'>Al registrarte aceptas todos los <Link to={"/terms&conditions"} className="text-indigo-600 hover:text-indigo-700 transition ease-in-out duration-150">terminos y condiciones</Link></p>
                    
                </form>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    loading: state.Auth.loading,
    isAuthenticated: state.Auth.isAuthenticated,
});

export default connect(mapStateToProps, { signup })(SignUp);