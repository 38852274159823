import Launders from "../../assets/img/launders-screenshoot.png";
import Services from "../../assets/img/services-screenshoot.webp";
import Clients from "../../assets/img/clients-screenshoot.webp";
import Reports from "../../assets/img/reports-screenshoot.webp";
import FeatureCard from "./FeatureCard";

function Features() {
    const features = [
        {
            title: "Digitalización de datos",
            data: [
                "Digitaliza toda la información de los lavados.",
                "Accede a datos históricos de manera rápida y sencilla.",
                "Evita errores manuales y mejora la precisión."
            ],
            photo: Launders
        },
        {
            title: "Generación de informes",
            data: [
                "Crea informes de ingreso semanales, mensuales y anuales.",
                "Visualiza tus datos en gráficos fáciles de entender.",
                "Toma decisiones informadas para hacer crecer tu negocio."
            ],
            photo: Reports
        },
        {
            title: "Notificaciones automáticas",
            data: [
                "Avisa a tus clientes cuando sus pedidos están listos.",
                "Envía notificaciones automáticas por WhatsApp.",
                "Mejora la comunicación y la satisfacción del cliente."
            ],
            photo: Launders
        },
        {
            title: "Gestión de servicios",
            data: [
                "Administra los servicios que ofreces de manera fácil.",
                "Actualiza precios y nombres en tiempo real."
            ],
            photo: Services
        },
        {
            title: "Gestión de clientes",
            data: [
                "Guarda la información de tus clientes de forma segura.",
                "Accede a datos de contacto y preferencias rápidamente.",
                "Ofrece un servicio personalizado."
            ],
            photo: Clients
        },
    ];

    return (
        <section id="features" className="text-white">
            <h2 className="text-5xl text-center font-semibold mb-8">
                Cómo puede ayudarte Lavamanager
            </h2>
            <div className="space-y-8">
                {features.map((feature, index) => (
                    <FeatureCard
                        key={feature.title}
                        photo={feature.photo}
                        title={feature.title}
                        data={feature.data}
                        reverse={index % 2 !== 0}
                    />
                ))}
            </div>
        </section>
    );
}

export default Features;
