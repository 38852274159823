import {
    GET_SERVICES_SUCCESS,
    GET_SERVICES_FAIL,
    DELETE_SERVICE_SUCCESS,
    DELETE_SERVICE_FAIL,
    CREATE_SERVICE_SUCCESS,
    CREATE_SERVICE_FAIL,
    EDIT_SERVICE_SUCCESS,
    EDIT_SERVICE_FAIL
} from '../actions/types';

const initialState = {
    services: null
};

export default function Services(state= initialState, action) { 
    const { type, payload } = action

    switch(type) {
        case GET_SERVICES_SUCCESS:
            return {
                ...state,
                services: payload
            }
        case GET_SERVICES_FAIL:
            return{
                ...state,
                services: null
            }
        case DELETE_SERVICE_SUCCESS:
            return {
                ...state,
                services: state.services.filter(service => service.service_name !== payload)
            }

        case DELETE_SERVICE_FAIL:
            return state;
        case CREATE_SERVICE_SUCCESS:
            return {
                ...state,
                services: [...state.services, payload]
            }

        case CREATE_SERVICE_FAIL:
            return state;

        case EDIT_SERVICE_SUCCESS:
            return {
                ...state,
                services: state.services.map(service => service.service_name === payload.service_name ? payload : service)
            }
        case EDIT_SERVICE_FAIL:
            return state;
        default:
            return state;
    }
}