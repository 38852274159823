import { Link } from "react-router-dom";
import Logo from "../../assets/img/isotipo-negativo-monocromo.svg";


function Navbar () {
    return(
        <header className="z-20 text-white flex justify-between p-4 bg-blur sticky top-0 items-center">
            <Link to={"/"} className="flex gap-1 items-center">
                <img
                src={Logo}
                height={40}
                width={40}
                alt="Logo" />
                <p className="text-3xl font-semibold">LavaManager</p>
            </Link>
            <nav className="flex gap-4 items-center">
                <div className="flex gap-4">
                <a href="/#features" className="hover:text-indigo-600 transition duration-150">Producto</a>
                <a href="/#pricing" className="hover:text-indigo-600 transition duration-150">Precios</a>
                <a href="/" className="hover:text-indigo-600 transition duration-150">Sobre nosotros</a>
                </div>
                <Link to='/login' className=" bg-gray-900 border-2 border-gray-900 py-2 px-3 rounded-lg hover:bg-gray-800 hover:border-gray-800 transition duration-150">
                    Iniciar sesión
                </Link>
                <Link to="/payment" className="bg-indigo-600 border-2 border-indigo-600 py-2 px-3 rounded-lg hover:bg-indigo-800 hover:border-indigo-800 transition duration-150">
                    Probar gratis
                </Link>
            </nav>
        </header>
    )
}

export default Navbar;