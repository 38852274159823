

function FeatureCard({ photo, title, data, reverse }) {
    return (
        <article className={`flex ${reverse ? 'flex-row-reverse' : 'flex-row'} items-center p-4 justify-center`}>
            {photo && (
                <img 
                    src={photo}
                    alt={title}
                    className="w-5/12 object-cover"
                />
            )}
            <div className="w-5/12 p-4">
                <h3 className="text-3xl font-semibold mb-2">{title}</h3>
                <ul className="list-disc pl-5">
                    {data.map((item, index) => (
                        <li className="text-lg text-gray-400 p-1" key={index}>{item}</li>
                    ))}
                </ul>
            </div>
        </article>
    );
}

export default FeatureCard;
