import React, { useState } from 'react';

function ClientEdit({ clientData, submitEdit, setShowEditClient }) {
    const [formData, setFormData] = useState(clientData);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        submitEdit(formData);
        setShowEditClient(false);
    };

    return (
        <form className="p-4 max-w-[850px] h-[calc(100vh-200px)] overflow-y-auto" onSubmit={handleSubmit}>
            <div>
                <h1 className="text-4xl font-bold">Editar Servicio</h1>
                    <div className="mb-4">
                        <label className="block text-sm font-medium">Nombre del Cliente</label>
                        <input 
                            type="text"
                            name="name"
                            className="shadow-sm rounded-md w-full px-3 py-2 border border-gray-300 text-gray-900"
                            value={formData.name}
                            onChange={handleChange}
                            placeholder="Name"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium">Email</label>
                        <input 
                            type="text"
                            name="email"
                            className="shadow-sm rounded-md w-full px-3 py-2 border border-gray-300 text-gray-900"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Email"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium">Teléfono</label>
                        <input 
                            type="text"
                            name="phone"
                            className="shadow-sm rounded-md w-full px-3 py-2 border border-gray-300 text-gray-900"
                            value={formData.phone}
                            onChange={handleChange}
                            placeholder="Phone"
                        />
                    </div>            
                    <button 
                        type="submit" 
                        className="w-40 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-md font-medium text-white bg-indigo-600 hover:bg-indigo-700">
                        Guardar
                    </button>
            </div>
        </form>
    );
}

export default ClientEdit;
