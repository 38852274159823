


function Card({ id, name, email, phone, onSelect, isSelected }) {
    return(
        <li 
            onClick={() => onSelect(id)}
            className={`p-2 bg-gray-900 hover:bg-gray-800 mb-1 rounded-lg cursor-pointer ${isSelected ? 'border-l-4 border-indigo-600' : ''}`}
        >
            <h2 className="text-white text-xl font-semibold">{name}</h2>
        </li>
    );
};


export default Card;